import { FenceZone, MicrofenceZone } from './MapDefaults';
import {
  ZoneType,
  SearchType,
  MicrofenceOrderByType,
  GeofenceOrderByType,
  OrderType,
  MicrofenceType,
  MicrofencePropType,
  OperatorType,
  ToolOrderByType,
  FenceZoneType,
  MicrofenceZoneType,
} from './types';

export const GeofenceOrderByTypes: GeofenceOrderByType[] = [
  { id: 'geofenceType', value: 'Geofence Type' },
  { id: 'name', value: 'Name' },
];

export const MicrofencePropTypes: MicrofencePropType[] = [
  { id: 'boundaryRSSI', value: 'Boundary RSSI' },
  { id: 'timeoutSeconds', value: 'Timeout (seconds)' },
];

export const MicrofenceTypes: MicrofenceType[] = [
  { id: 'beacon', value: 'Beacon' },
  { id: 'device', value: 'Device' },
  { id: 'gateway', value: 'Gateway' },
];

export const MicrofenceOrderByTypes: MicrofenceOrderByType[] = [
  { id: 'microfenceType', value: 'Microfence Type' },
  { id: 'name', value: 'Name' },
];

export const OperatorTypes: OperatorType[] = [
  { id: 'EQUAL_TO', value: 'Equals' },
  { id: 'GREATER_THAN', value: 'Greater Than' },
  { id: 'LESS_THAN', value: 'Less Than' },
];

export const OrderTypes: OrderType[] = [
  { id: 'ASC', value: 'Ascending' },
  { id: 'DESC', value: 'Descending' },
];

export const SearchTypes: SearchType[] = [
  { id: 'ACTIVE_ASSETS', value: 'Active Assets' },
  { id: 'BEACONS', value: 'Beacons' },
  { id: 'DEVICES', value: 'Devices' },
  { id: 'GEOFENCES', value: 'Geofences', forEditing: true },
  { id: 'LOCATIONS', value: 'Locations', forEditing: true },
  { id: 'MICROFENCES', value: 'Microfences', forEditing: true },
  { id: 'TOOLS', value: 'Tools' },
];

export const ToolOrderByTypes: ToolOrderByType[] = [
  { id: 'toolType', value: 'Tool Type' },
  { id: 'id', value: 'ID' },
];

export const ZoneTypes: ZoneType[] = [
  { id: FenceZone.breach, value: 'Breach Zones' },
  { id: FenceZone.buffer, value: 'Buffer Zones' },
  { id: 'MULTIPOLYGON', value: 'Cleared Zones' },
  { id: 'POLYGON', value: 'Geofences' },
  { id: 'LINE', value: 'Tripwires' },
];

export const FenceZoneTypes: FenceZoneType[] = [
  { id: FenceZone.breach, value: 'Breach' },
  { id: FenceZone.buffer, value: 'Buffer' },
  { id: FenceZone.cleared, value: 'Cleared' },
  { id: FenceZone.none, value: 'No Zone' },
];

export const MicrofenceZoneTypes: MicrofenceZoneType[] = [
  { id: MicrofenceZone.dumping, value: 'Dumping' },
  { id: MicrofenceZone.loading, value: 'Loading' },
  { id: MicrofenceZone.maintenance, value: 'Maintenance' },
  { id: MicrofenceZone.none, value: 'No Zone' },
];
